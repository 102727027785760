import axios, { resHandle } from "@/plugins/axios";
/**
 * @description 健康测评列表
 * @param vCom Vue组件
 */
const GetCepingList = (vCom: any, params: any, loading?: any): Promise<any> => {
    return new Promise((resolve, reject) => {
        axios.post("api/project/ce_ping_list", params).then(({ data }) => {
            resHandle(vCom, data, loading).then(() => {
                resolve(data.data);
            }).catch(() => {
                reject();
            });
        }).catch(() => {
            reject();
        });
    });
};
/**
 * @description 健康测评列表高级筛选条件选项
 * @param vCom Vue组件
 */
const GetCepingListFilter = (vCom: any, params: any, loading?: any): Promise<any> => {
    return new Promise((resolve, reject) => {
        axios.get("api/project/template_search_topics", params).then(({ data }) => {
            resHandle(vCom, data, loading).then(() => {
                resolve(data.data);
            }).catch(() => {
                reject();
            });
        }).catch(() => {
            reject();
        });
    });
};
export { GetCepingList, GetCepingListFilter }