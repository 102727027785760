

















import { Component, Vue, Prop } from "vue-property-decorator";
import MainHeader from "../../main/components/main-header/main-header.vue";
import NavLeft from "../../main/components/nav-left/nav-left.vue";
import Ceping from "../components/ceping/ceping.vue";
@Component({
  components: {
    MainHeader,
    NavLeft,
    Ceping,
  },
})
export default class Name extends Vue {
  private source: any = "";
  private get configuration() {
    this.source = this.$store.state.configuration.jiances[0].label;
    return this.$store.state.configuration;
  }
  private cepingChange(e: any) {}
}
