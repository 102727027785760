

























































































































































import { Component, Vue, Prop, Watch } from "vue-property-decorator";
@Component({})
export default class Name extends Vue {
  @Prop()
  private data: any;
  private xinzangOptions: any = [
    "心脏骤停或心肌梗死或冠脉搭桥手术",
    "缺血性卒中或短暂性脑缺血发作",
    "急性冠脉综合征",
    "稳定性冠心病",
    "缺血性心肌病",
    "外周动脉疾病（除冠状动脉及主动脉以外的其他大动脉引起的动脉粥样硬化性病变，导致腿部、手臂或其他部位动脉血流减少）",
    "血管成形术或支架置入术",
    "冠状动脉造影或冠状动脉CT证实两支或以上主要冠状动脉狭窄>50%",
    "超声证实劲动脉狭窄>50%",
    "糖尿病≥10年或有靶器官损害（微量白蛋白尿、视网膜病变或肾病）",
    "I型糖尿病患者病程>20年",
    "中重度慢性肾病（eGFR<60 ml/（min*1.73m*m））",
    "家族性高胆固醇血症",
    "总胆固醇>8.0mmol/L和（或）低密度脂蛋白（LDL-C）>4.9mmol/L",
    "血压≥180/110 mmHg",
    "泛血管疾病的病史",
    "我没有上述疾病或不知道",
  ];
}
