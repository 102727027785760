




















































































































































































































































































































































































































































































































import { Component, Vue, Prop } from "vue-property-decorator";
import echarts from "echarts";
import { mixins } from "vue-class-component";
import { listMixin } from "@/mixins/listMixin";
import { getStatistics, PostLaiyuans, ImportXinzang } from "@/request/yichang";
import { delContents, getAllTemplate } from "@/request/custom";
import { patientDetail } from "@/request/doctor";
import { GetCepingList, GetCepingListFilter } from "@/request/report";
import Read from "@/components/xinzangwuyou/xinzangwuyou-read.vue";
import TimeRange from "@/components/time-range/time-range.vue";
@Component({
  components: {
    Read,
    TimeRange,
  },
})
export default class Name extends mixins(listMixin) {
  private laiyuans: any = [];
  private laiyuanData: any = {};
  private curLaiyuan: any = "";
  private resultData: any = [];
  private data: any = [];
  private detail: any = {};
  private ifShowRead: any = false;
  private fengxian: any = ["低危", "中危", "高危", "极高危"];
  private pinggushijian: any = [
    "近7天",
    "近1个月",
    "近3个月",
    "近半年",
    "近1年",
    "自定义",
  ];
  private colors: any = [
    "#FFA7CC",
    "#46AAAA",
    "#FFAA6A",
    "#A185D3",
    "#82D1F1",
    "#EC7E68",
    "#F6C63C",
    "#7784A2",
    "#75DDB5",
    "#759FFC",
    "#FFA7CC",
    "#46AAAA",
    "#FFAA6A",
    "#A185D3",
    "#82D1F1",
    "#EC7E68",
    "#F6C63C",
    "#7784A2",
    "#75DDB5",
    "#759FFC",
    "#FFA7CC",
    "#46AAAA",
    "#FFAA6A",
    "#A185D3",
    "#82D1F1",
    "#EC7E68",
    "#F6C63C",
    "#7784A2",
    "#75DDB5",
    "#759FFC",
    "#FFA7CC",
    "#46AAAA",
    "#FFAA6A",
    "#A185D3",
    "#82D1F1",
    "#EC7E68",
    "#F6C63C",
    "#7784A2",
    "#75DDB5",
    "#759FFC",
  ];
  private importData: any = {
    startTime: "",
    endTime: "",
    source: [],
  };
  private ifShowImport: any = false;
  private cepings: any = [];
  private ifShowPrimarySearch: any = false;
  private filterItems: any = [];
  private get href() {
    return window.location.href;
  }
  private nameChange() {
    this.filters["result_filter"] = "";
    this.filters["研究指标"] = [];
    this.filters["来源"] = "";
    this.filters["评估时间"] = "";
    this.filters["风险分层"] = "";
    this.filters["条件"] = "";
    this.getFilter();
    this.search();
  }
  private getFilter() {
    if (!this.filters["健康测评"]) {
      this.filters["研究指标"] = [];
      return;
    }
    const params: any = {
      params: {
        测评名称: this.filters["健康测评"],
      },
    };
    GetCepingListFilter(this, params).then((res: any) => {
      this.filterItems = res;
    });
  }
  /**
   * @description 重置高级搜索
   */
  private resetPrimarySearch() {
    this.filters.ifPrimarySearched = false;
    this.ifShowPrimarySearch = false;
    this.filters.timeWay = "";
    this.filters.time = [];
    this.filters["条件"] = "";
    this.filters["研究指标"] = [];
    this.search();
  }
  /**
   * @description 取消高级筛选
   */
  private cancelPrimarySearch() {
    this.ifShowPrimarySearch = false;
  }
  /**
   * @description 确定高级筛选
   */
  private confirmPrimarySearch() {
    this.ifShowPrimarySearch = false;
    this.filters.current_page = 1;
    this.filters.ifPrimarySearched = true;
    this.getList();
  }
  /**
   * @description 添加研究指标变量
   */
  private addTiaojian() {
    // this.getFilterItems();
    this.filters["研究指标"].push({
      template_id: "心脏无忧筛查",
      result: {},
      selected: {},
      items: this.filterItems,
    });
    this.forceUpdate();
  }
  /**
   * @description 删除一个条件
   */
  private deleteTiaojian(index: any) {
    this.filters["研究指标"].splice(index, 1);
    this.forceUpdate();
  }
  private forceUpdate() {
    this.$forceUpdate();
  }
  private getFilterItems() {
    this.filterItems = [
      {
        condition: ["等于", "不等于"],
        id: "2200202",
        origin_type: "文本域",
        origin_value: "",
        template_id: "tem622ededf9e752a037f8daa6c",
        title:
          "请输入内容（建议您下载科大讯飞输入法，通过语音录入患者的病例内容）",
        type: "字符串",
      },
      {
        condition: ["等于", "不等于"],
        id: "2200207",
        origin_type: "单选补充文字",
        origin_value: [
          "隐痛",
          "跳痛",
          "灼痛",
          "刺痛",
          "窜痛",
          "酸痛",
          "无",
          "冷痛",
          "胀痛",
        ],
        template_id: "tem622ededf9e752a037f8daa6c",
        title: "颈痛",
        type: "下拉单选",
      },
      {
        condition: ["等于", "不等于"],
        id: "2200208",
        origin_type: "单选补充文字",
        origin_value: [
          "隐痛",
          "跳痛",
          "灼痛",
          "刺痛",
          "窜痛",
          "酸痛",
          "无",
          "冷痛",
          "胀痛",
        ],
        template_id: "tem622ededf9e752a037f8daa6c",
        title: "头痛",
        type: "下拉单选",
      },
      {
        condition: ["等于", "不等于"],
        id: "2200209",
        origin_type: "多选补充文字",
        origin_value: [
          "屈伸不利",
          "侧曲受限",
          "难以支撑",
          "旋转受限",
          "无",
          "广泛僵硬",
        ],
        template_id: "tem622ededf9e752a037f8daa6c",
        title: "强直僵硬",
        type: "下拉多选",
      },
      {
        condition: ["等于", "不等于"],
        id: "2200210",
        origin_type: "单选",
        origin_value: ["无", "有"],
        template_id: "tem622ededf9e752a037f8daa6c",
        title: "酸胀",
        type: "下拉单选",
      },
      {
        condition: ["等于", "不等于"],
        id: "2200211",
        origin_type: "单选",
        origin_value: ["无", "有"],
        template_id: "tem622ededf9e752a037f8daa6c",
        title: "头晕",
        type: "下拉单选",
      },
      {
        condition: ["等于", "不等于"],
        id: "2200212",
        origin_type: "单选",
        origin_value: ["无", "有"],
        template_id: "tem622ededf9e752a037f8daa6c",
        title: "头昏",
        type: "下拉单选",
      },
      {
        condition: ["等于", "不等于"],
        id: "2200213",
        origin_type: "多选补充文字",
        origin_value: ["痛", "无力", "酸", "无", "木", "麻"],
        template_id: "tem622ededf9e752a037f8daa6c",
        title: "上肢症状",
        type: "下拉多选",
      },
      {
        condition: ["等于", "不等于"],
        id: "2200214",
        origin_type: "多选补充文字",
        origin_value: ["痛", "无力", "酸", "无", "木", "麻"],
        template_id: "tem622ededf9e752a037f8daa6c",
        title: "下肢症状",
        type: "下拉多选",
      },
      {
        condition: ["等于", "不等于"],
        id: "2200216",
        origin_type: "单选",
        origin_value: [
          "≤72小时",
          "72小时-3个月",
          "3个月-6个月",
          "6个月-1年",
          "1年-3年",
          "3年-5年",
          "5年-10年",
          "10年以上",
        ],
        template_id: "tem622ededf9e752a037f8daa6c",
        title: "病程",
        type: "下拉单选",
      },
      {
        condition: ["等于", "不等于"],
        id: "2200217",
        origin_type: "多选补充文字",
        origin_value: [
          "阴雨天",
          "低头",
          "久坐",
          "外伤",
          "劳累",
          "药物",
          "情绪",
          "受寒",
        ],
        template_id: "tem622ededf9e752a037f8daa6c",
        title: "发病或加重诱因",
        type: "下拉多选",
      },
      {
        condition: ["等于", "不等于"],
        id: "2200218",
        origin_type: "多选补充文字",
        origin_value: [
          "整骨",
          "推拿",
          "理疗",
          "针灸",
          "中药",
          "手术",
          "NSAIDs",
        ],
        template_id: "tem622ededf9e752a037f8daa6c",
        title: "治疗史",
        type: "下拉多选",
      },
      {
        condition: ["等于", "不等于"],
        id: "2200219",
        origin_type: "多选补充文字",
        origin_value: [
          "风湿免疫病",
          "消化系统疾病",
          "血液系统疾病",
          "呼吸系统疾病",
          "内分泌系统疾病",
          "无",
          "神经系统疾病",
          "泌尿系统疾病",
          "循环系统疾病",
        ],
        template_id: "tem622ededf9e752a037f8daa6c",
        title: "既往病史",
        type: "下拉多选",
      },
    ];
  }
  private importTimeChange(d: any) {
    this.importData = JSON.parse(JSON.stringify(d));
  }
  private importSure() {
    // if (!this.importData.type) {
    //   this.$message.warning("请选择导出类型！");
    //   return;
    // }
    if (!this.importData.startTime) {
      this.$message.warning("请选择开始时间！");
      return;
    }
    if (!this.importData.endTime) {
      this.$message.warning("请选择结束时间！");
      return;
    }
    // if (this.importData.source.length == 0) {
    //   this.$message.warning("请选择渠道来源！");
    //   return;
    // }
    const params: any = {
      // 导出类型: this.importData.type,
      起始时间: this.importData.startTime,
      结束时间: this.importData.endTime,
      来源: this.importData.source,
    };
    const loading = this.$loading({
      lock: true,
      text: "加载中……",
      spinner: "el-icon-loading",
      customClass: "loadClass",
      background: "rgba(255, 255, 255, 0.7)",
    });
    ImportXinzang(this, params, loading)
      .then((res: any) => {
        loading.close();
        if (res.count === 0) {
          this.$message.warning("当前条件下无数据可导出！");
        } else {
          window.open(res.path);
          this.$message.success("导出成功！");
          this.ifShowImport = false;
        }
      })
      .catch(() => {
        loading.close();
      });
  }
  private openImport() {
    this.ifShowImport = true;
    this.importData = {
      startTime: "",
      endTime: "",
      source: [],
    };
  }
  private refresh() {
    this.curLaiyuan = {};
    this.drawResult();
  }
  private pinggushijianChange(e: any) {
    this.filters["评估时间"] = e;
    this.$forceUpdate();
    if (this.filters["评估时间"] !== "自定义") {
      this.search();
    }
  }
  private fengxianChange() {
    this.search();
  }
  private timeChange(d: any) {
    this.filters = JSON.parse(JSON.stringify(d));
    this.search();
  }
  private goReport(item: any) {
    const url: any = this.href.split("/#/")[0];
    window.open(url + "/#/main/report/ceping-report?id=" + item.content_id);
    // if (item["报告类型"] == "心脏无忧筛查报告") {
    //   window.open(
    //     url +
    //       "/#/main/workstation/patient-xinzangwuyou-report?id=" +
    //       item.content_id
    //   );
    // } else {
    //   window.open(url + "/#/main/report/ceping-report?id=" + item.content_id);
    // }
  }
  private goRead(item: any) {
    //先获取患者信息
    const params = {
      patient_id: item.patient_id,
      source: "pc医生",
    };
    patientDetail(this, params).then((res: any) => {
      this.$store.commit("updatePatient", res);
      this.$store.commit("updateIfCompose", false);
      this.$router.push({
        path: "/main/report/ceping-edit",
        query: {
          content_id: item.content_id,
          type: "read",
        },
      });
    });
  }
  private deleteOne(item: any) {
    this.$confirm("您是否确定删除？", "删除", {
      customClass: "commonConfirm",
    })
      .then(() => {
        const params: any = {
          data: {
            content_id: item.content_id,
          },
        };
        delContents(this, params).then((data: any) => {
          this.$message.success("删除成功");
          this.getList();
        });
      })
      .catch((e) => {
        console.log("取消了");
      });
  }
  private getLaiyuan() {
    PostLaiyuans(this).then((res: any) => {
      this.laiyuans = res;
      // this.laiyuans.unshift("全部");
    });
  }
  private drawLaiyuan() {
    getStatistics(this, {}).then((res: any) => {
      this.laiyuanData = res["来源"];
      const Chart: any = echarts.init(this.$refs.laiyuan as HTMLCanvasElement);

      const options = {
        color: this.colors,
        tooltip: {
          trigger: "axis",
          axisPointer: {
            type: "shadow",
          },
        },
        grid: {
          left: "3%",
          right: "4%",
          bottom: "3%",
          containLabel: true,
        },
        xAxis: [
          {
            type: "category",
            data: this.laiyuanData.category,
            axisTick: {
              alignWithLabel: true,
            },
          },
        ],
        yAxis: [
          {
            type: "value",
          },
        ],
        series: [
          {
            type: "bar",
            barWidth: "60%",
            data: this.laiyuanData.series[0].data,
          },
        ],
      };
      Chart.setOption(options, true);
      // 点击事件
      Chart.off("click");
      Chart.on("click", (p: any) => {
        this.curLaiyuan = p;
        this.drawResult();
      });
    });
  }
  private drawResult() {
    const params: any = {
      来源: "",
    };
    if (this.curLaiyuan.name) {
      params["来源"] = this.curLaiyuan.name;
    }
    getStatistics(this, params).then((res: any) => {
      this.resultData = res["结论"];
      const Chart: any = echarts.init(this.$refs.result as HTMLCanvasElement);
      const options = {
        color: this.colors,
        tooltip: {
          trigger: "item",
          formatter: "{b0}: {c0}<br />{d}%",
        },
        legend: {
          orient: "horizontal",
          bottom: "6",
        },
        series: [
          {
            type: "pie",
            data: this.resultData,
            radius: "70%",
            //   label: {
            //     show: false,
            //     position: "center",
            //   },
            emphasis: {
              itemStyle: {
                shadowBlur: 10,
                shadowOffsetX: 0,
                shadowColor: "rgba(0, 0, 0, 0.5)",
              },
            },
            //   center: ["60%", "50%"],
          },
        ],
      };
      Chart.setOption(options, true);
      // 点击事件
      Chart.off("click");
      Chart.on("click", (p: any) => {});
    });
  }
  private focus(e: any) {
    if (e) {
      this.getCepings("");
    }
  }
  private getCepings(e: any) {
    const params: any = {
      search: e,
      来源: "医生端",
      need_score: true,
    };
    getAllTemplate(this, params).then((res: any) => {
      this.cepings = res;
    });
  }
  private getData() {
    this.getLaiyuan();
    this.drawLaiyuan();
    this.drawResult();
    this.getList();
  }
  getList() {
    const params: any = {
      测评名称: this.filters["健康测评"],
      来源: this.filters["来源"],
      period: this.filters["评估时间"],
      search: this.filters.search,
      current_page: this.filters.current_page,
      page_count: this.filters.page_count,
      begin_date: this.filters.startTime,
      end_date: this.filters.endTime,
      result_filter: this.filters.result_filter,
      条件: this.filters["条件"],
      高级筛选: [],
    };
    if (this.filters["评估时间"] === "自定义") {
      params.begin_date = this.filters.startTime;
      params.end_date = this.filters.endTime;
    }
    if (this.filters["研究指标"] && this.filters["研究指标"].length > 0) {
      this.filters.ifPrimarySearched = true;
      const arr: any[] = [];
      this.filters["研究指标"].map((item: any) => {
        const i = JSON.parse(JSON.stringify(item.selected));
        i.condition = item.result.condition;
        i.value = item.result.value;
        arr.push(i);
      });
      params["高级筛选"] = arr;
    }
    const loading = this.$loading({
      lock: true,
      text: "加载中……",
      spinner: "el-icon-loading",
      customClass: "loadClass",
      background: "rgba(255, 255, 255, 0.7)",
    });
    localStorage.setItem("shaichajianceFilter", JSON.stringify(this.filters));
    GetCepingList(this, params)
      .then((data: any) => {
        loading.close();
        this.data = data.datas;
        this.filters.total_count = data.total_count;
        this.filters.total_page = data.total_page;
        if (this.data.length === 0) {
          this.ifShowEmpty = true;
        } else {
          this.ifShowEmpty = false;
        }
      })
      .catch(() => {
        loading.close();
      });
  }
  mounted() {
    this.filters["来源"] = "";
    this.filters["评估时间"] = "";
    this.filters["风险分层"] = "";
    this.filters["条件"] = "";
    this.filters["研究指标"] = [];
    this.filters["健康测评"] = "心脏无忧筛查";
    this.filters.ifPrimarySearched = false;
    if (localStorage.getItem("shaichajianceFilter")) {
      this.filters = JSON.parse(
        localStorage.getItem("shaichajianceFilter") as any
      );
    }
    this.getCepings("");
    this.getLaiyuan();
    this.drawLaiyuan();
    this.drawResult();
    this.getList();
    this.getFilter();
  }
}
